<template>
    <div>
        <video
            playsinline=""
            webkit-playsinline="true"
            x5-playsinline="true"
            preload="auto"
            controls
            class="video"
            :src="src"
        />
    </div>
</template>

<script>
export default {
    data() {
        return {
            src: ""
        };
    },

    mounted() {
        console.log(this.$router.history.current);

        this.src = this.$router.history.current.query.src;
    }
};
</script>

<style lang="less" scoped>
.video {
    width: 100vw;
    height: 100vh;
    background-color: #000;
}
</style>
